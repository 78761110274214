/**
 * 输入框类型
 */
export enum InputBoxTypeEnum {
    /**
     * 多行文本
     */
    TextArea = 0,
    /**
     * 文本
     */
    TEXT = 1,
    /**
     * 上传文件
     */
    UploadFile = 2,
    /**
     * 密码框
     */
    Password = 3,
    /**
     * 日期+时间控件
     */
    DateTime = 4,
    /**
     * 日期控件
     */
    Date = 5,
    /**
     * 数字类型控件
     */
    Number = 6
}

/**
 * 验证码类型模式
 */
 export enum SlideVerificationTypeEnum {
    /**
     * 手机号申请验证
     */
    VerificationPhone = 1,
    /**
     * 账号授权
     */
    AccountAuthorization = 2
}