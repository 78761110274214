<template>
  <div class="slide_window">
    <div class="slide_content">
      <img src="image/x.png" @click="CloseSilde" class="silde_close" />
      <span class="slide_title">验证码</span>
      <div class="slide_body">
        <div id="slider">
          <div id="slider_bg"></div>
          <span id="label">>></span>
          <span id="labelTip">拖动滑块验证</span>
        </div>
        <div id="huadongImage">
          <div id="imagediv">
            <img :src="base64Image" alt="看不清？点击更换" id="image" />
            <div id="divrefresh" @click.stop="divrefreshClick" class="shaxin">
              <img src="image/shaxin.jpg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SliderUnlock from "@/ts/SliderUnlock";
import Tools from "@/ts/Tools";
import Global from "@/ts/Global";

var num = 1;
var checkCode = [];
var slider = null;

window.PointClick = function (e, obj) {
  e.stopPropagation();
  num = 1;
  checkCode = [];
  let p = obj.parentElement;
  p.removeChild(obj);
};

export default {
  components: {},
  props: {
    extend_obj: Object, //扩展参数
    verification_type: Number, //验证类型 1手机验证
    slide_success_callback: function (d) {},
    slide_error_callback: function (msg) {},
    slide_cancel_callback: function () {},
  },
  data() {
    return {
      is_binding: false,
      base64Image: "",
    };
  },
  watch: {},
  methods: {
    CloseSilde() {
      this.slide_cancel_callback && this.slide_cancel_callback();
      this.$parent.CloseSlideVerification();
    },
    SliderInit() {
      slider = new SliderUnlock(
        "#slider",
        {
          successLabelTip: "验证成功",
        },
        () => {
          this.HuaDongCode();
        }
      );
      slider.init();
    },
    HuaDongCode() {
      num = 1;
      checkCode = [];
      Tools.GetHtml(
        "GetVerificationCodeImage",
        {
          phone: this.extend_obj.phone,
          verification_type: this.verification_type,
        },
        (res) => {
          if (Tools.CheckHtmlIsOK(res)) {
            let d = res.data;
            let base64Image = d.base64Image;
            let msg = d.msg;
            this.base64Image = base64Image;
            document.querySelector("#imagediv").style = "display:block";
            document.querySelector("#labelTip").innerHTML = msg;
            if (this.is_binding) return;
            this.is_binding = true;
            this.ImageClick();
            this.DivrefreshClick();
          } else {
            slider.index = slider.min;
            slider.isOk = false;
            let labelTip = document.querySelector("#labelTip");
            labelTip.innerText = "拖动滑块验证";
            labelTip.style = "color:black;";
          }
        }
      );
    },
    ImageClick() {
      let imagediv = document.querySelector("#imagediv");
      imagediv.addEventListener("click", () => {
        var pos = this.GetMousePos(imagediv);
        this.CreatePoint(imagediv, pos);
      });
    },
    DivrefreshClick() {
      document.querySelector("click", () => {
        this.HuaDongCode();
        this.ClearAllPoint();
      });
    },
    divrefreshClick() {
      this.ClearAllPoint();
      this.HuaDongCode();
    },
    /**
     * 删除所有的点
     */
    ClearAllPoint() {
      let all_point_list = document.querySelectorAll(".point-area");
      for (let i = 0; i < all_point_list.length; i++) {
        const obj = all_point_list[i];
        let p = obj.parentElement;
        p.removeChild(obj);
      }
      num = 1;
      checkCode = [];
    },
    AppendHtml(elem, value) {
      var node = document.createElement("div"),
        fragment = document.createDocumentFragment(),
        childs = null,
        i = 0;
      node.innerHTML = value;
      childs = node.childNodes;
      for (; i < childs.length; i++) {
        fragment.appendChild(childs[i]);
      }
      elem.appendChild(fragment);
      childs = null;
      fragment = null;
      node = null;
    },
    CreatePoint(imagediv, pos) {
      if (num == 2) {
        this.PostcheckCode();
      }
      let dian_html = `<div class="point-area aaa" 
        onclick="PointClick(event,this)" 
        style="
          background-color: #539ffe;
            color: #fff;
            z-index: 9999;
            width: 25px;
            height: 25px;
            text-align: center;
            line-height: 25px;
            border-radius: 20%;
            position: absolute;
            border: 2px solid white;
        top:${parseInt(pos.y - 10)}px;left:${parseInt(pos.x - 10)}px;">
        ${num}
      </div>`;
      this.AppendHtml(imagediv, dian_html);

      ++num;
    },
    PostcheckCode() {
      Tools.GetHtml(
        "CheckVerifcationCode",
        {
          verifcation_code: JSON.stringify(checkCode),
          verification_type: this.verification_type,
          phone: this.extend_obj?.phone,
          authorization_pwd: this.extend_obj?.pwd,
          invite_code: this.extend_obj?.invite_code,
        },
        (res) => {
          let d = res.data;
          if (Tools.CheckHtmlIsOK(res)) {
            document.querySelector("#labelTip").innerHTML = "验证成功";
            document.querySelector("#huadongImage").style = "display:none;";
            document.querySelector("#labelTip").style = `'color': '#fff';`;
            //执行下一步的代码
            this.slide_success_callback && this.slide_success_callback(d);
            Global.CloseSlideVerification();
          } else {
            document.querySelector("#labelTip").innerHTML = d;

            this.slide_error_callback &&
              this.slide_error_callback(res?.error_msg ?? "验证错误");
            this.HuaDongCode();
          }
          this.ClearAllPoint();
        }
      );
    },

    GetMousePos(obj, event) {
      var e = event || window.event;
      // var x = e.clientX - (obj.offsetLeft - window.scrollX);
      // var y = e.clientY - (obj.offsetTop - window.scrollY);
      let x = e.offsetX;
      let y = e.offsetY;
      checkCode.push({ _X: parseInt(x), _Y: parseInt(y) });
      console.log(`clientX=${x} clientY:${y}`);

      return { x: parseInt(x), y: parseInt(y) };
    },
  },
  mounted() {
    this.SliderInit();
  },
};
</script>
<style scoped lang="less">
@import url("@/assets/css/style.css");
.slide_window {
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
  height: 100vh;
  z-index: 300;

  .slide_content {
    padding: 20px 20px 20px 20px;
    background-color: rgb(31, 31, 31);
    border-radius: 10px;
    position: relative;
    .silde_close {
      position: absolute;
      top: 5px;
      right: 5px;
      width: 20px;
      height: 20px;
    }
    .slide_title {
      width: 100%;
      display: block;
      text-align: center;
      margin-bottom: 10px;
      font-size: 20px;
    }

    .slide_body {
      display: flex;
      flex-direction: column;
      width: 200px;
      height: auto;
      position: relative;
    }
  }
}

#huadongImage {
  display: block;
  // border: 1px solid #ccc;
  background-color: white;
}

#slider {
  width: 100%;
  height: 40px;
  border-radius: 2px;
  background-color: #dae2d0;
  overflow: hidden;
  text-align: center;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  position: relative;
}

#imagediv {
  position: relative;
  display: none;
}
#slider_bg {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #7ac23c;
  z-index: 1;
}

#label {
  width: 46px;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: -1px;
  height: 42px;
  line-height: 42px;
  border: 1px solid #ccc;
  background: #fff;
  z-index: 3;
  cursor: move;
  color: #ff9e77;
  font-size: 16px;
  font-weight: 900;
}

#labelTip {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 13px;
  font-family: microsoft yahei, serif;
  color: #787878;
  line-height: 38px;
  text-align: center;
  z-index: 2;
}
.shaxin {
  position: absolute;
  cursor: pointer;
  top: 0px;

  right: 0px;
  img {
    // border-radius: 50%;
  }
}
</style>