/**
 * 超级定时器
 */
export default class SuperTimer {
    private static interval_list: {
        key: string,
        index: NodeJS.Timer
    }[] = [];
    /**
     * 启动持续定时器
     * @param call_back 返回值true继续运行 false停止运行
     * @param ms 
     */
    public static Start(key: string, call_back: () => boolean, ms: number) {
        console.log(`开始定时器:${key}`);

        /**
         * 停止相同Key定时器
         */
        this.Stop(key);

        let interval = setInterval(() => {
            let b = call_back && call_back();
            if (!b) {
                this.Stop(interval);
            }
        }, ms);
        this.interval_list.push({
            key: key,
            index: interval
        });
    }

    /**
     * 是否存在某个定时器
     * @param id 
     * @returns 
     */
    public static HaveKey(id: NodeJS.Timer | string) {
        for (let i = 0; i < this.interval_list.length; i++) {
            const v = this.interval_list[i];
            if (typeof id == "string") {
                if (v.key == id) {
                    return true;
                }
            }
            else if (v.index == id) {
                return true;
            }
        }
        return false;
    }

    public static StartAsync(key: string, call_back: () => Promise<boolean>, ms: number) {
        console.log(`开始定时器:${key}`);
        /**
         * 停止相同Key定时器
         */
        this.Stop(key);

        let interval = setInterval(async () => {
            let b = call_back && await call_back();
            if (!b) {
                this.Stop(interval);
            }
        }, ms);
        this.interval_list.push({
            key: key,
            index: interval
        });
    }

    /**
     * 停止全部定时器
     */
    public static ClearAllInterval() {
        for (let i = 0; i < this.interval_list.length; i++) {
            const v = this.interval_list[i];
            clearInterval(v.index);
        }
        this.interval_list.splice(0, this.interval_list.length);
    }

    /**
     * 停止定时器
     * @param id 
     */
    public static Stop(id: NodeJS.Timer | string) {
        for (let i = 0; i < this.interval_list.length; i++) {
            const v = this.interval_list[i];
            if (typeof id == "string") {
                if (v.key == id) {
                    this.interval_list.splice(i, 1);
                    clearInterval(v.index);
                    console.log(`停止定时器:${v.key}`);
                    break;
                }
            }
            else if (v.index == id) {
                this.interval_list.splice(i, 1);
                clearInterval(v.index);
                console.log(`停止定时器:${v.key}`);
                break;
            }
        }
    }
}