<template>
  <!-- 图形验证码 -->
  <Slide
    :extend_obj="extend_obj"
    :verification_type="slide_verification_type"
    :slide_success_callback="slide_success_callback"
    :slide_error_callback="slide_error_callback"
    :slide_cancel_callback="slide_cancel_callback"
    v-if="show_slide"
  ></Slide>

  <Alert
    class="alert_css"
    v-if="show_alert"
    :show_alert_state="show_alert_state"
    :message="alert_message"
  ></Alert>
  <router-view />
</template>
<script>
import Global from "./ts/Global";
import SuperTimer from "./ts/SuperTimer";
import Tools from "./ts/Tools";
import Alert from "@/components/alert.vue";
import Slide from "@/components/Slide.vue";
import { inject, provide } from "@vue/runtime-core";
export default {
  setup() {
    provide("gzh_config_vo", Global.gzh_config_vo);
    provide("platform_vo", Global.platform_vo);
    return {
      gzh_config_vo: Global.gzh_config_vo,
      platform_vo: Global.platform_vo,
    };
  },
  data() {
    return {
      // 验证码相关
      show_slide: false, // 显示验证码
      extend_obj: {}, //扩展参数
      slide_verification_type: 0, //藏品类型
      slide_success_callback: function (d) {},
      slide_error_callback: function (msg) {},
      slide_cancel_callback: function () {},

      // 验证码相关
      show_alert: false,
      show_alert_state: 0,
    };
  },
  components: {
    Alert,
    Slide,
  },
  methods: {
    /**
     * 打开验证码
     */
    OpenSlideVerification(
      extend_obj,
      slide_verification_type,
      slide_success_callback,
      slide_error_callback,
      slide_cancel_callback
    ) {
      this.show_slide = true; // 显示验证码
      this.slide_verification_type = slide_verification_type;
      this.extend_obj = extend_obj; //扩展参数
      this.slide_success_callback = slide_success_callback; //验证码回调
      this.slide_error_callback = slide_error_callback; //验证码回调
      this.slide_cancel_callback = slide_cancel_callback; //验证码回调
    },
    /**
     * 隐藏验证码
     */
    CloseSlideVerification() {
      this.show_slide = false;
    },

    ShowAlert(message, state) {
      this.alert_message = message;
      this.show_alert = true;
      this.show_alert_state = state;
      SuperTimer.Start(
        "alert_time_out",
        () => {
          this.show_alert = false;
          return false;
        },
        1500
      );
    },
  },
  async created() {
    Global.app = this;
  },
  mounted() {},
};
</script>

<style lang="scss">
html,
body {
  height: 100%;
  width: 100%;
  padding: 0px;
  margin: 0px;
  background-color: rgba(7, 13, 13, 1);
  color: white;
}
#app {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

/* 灰色 */
.to_gray {
  /*grayscale(val):val值越大灰度就越深*/
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
}
</style>
