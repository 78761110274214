import router from '@/router';
import Config from './Config';
import BaseWallet, { ChangeEnum, MnemonicStrengthEnum, WordLanguageEnum } from './Eth/BaseWallet';
import Global from './Global';
import BackDataVO from './vo/BackDataVO';
var cryptoJs = require("crypto-js");
var SparkMD5 = require('spark-md5');
export default class Tools {
    /**
     * 检测是否是数字
     * @param v 
     * @returns 
     */
    public static IsNumber(v: any): boolean {
        if (typeof v == "number") {
            return true;
        }
        else {
            if (isNaN(parseInt(v)))
                return false;
            return true;
        }
    }
    /**
     * 转换成数字
     * @param v 
     * @returns 
     */
    public static ToNumber(v: number | string): number {
        if (typeof v == "number") {
            return v;
        } else {
            let ret_value = "";
            for (let i = 0; i < v.length; i++) {
                const n = v[i];
                if (isNaN(parseInt(n)) || n == '.') {
                    ret_value += n;
                }
            }
            return parseFloat(ret_value);
        }
    }

    /**
     * 生成钱包
     * @returns 
     */
    public static async MakeWalletAsync(): Promise<{
        mnemonic: string,
        address: string,
        privateKey: string,
        publicKey: string,
    }> {
        let ms = BaseWallet.CreateMnemonicAndSeed(
            "",
            MnemonicStrengthEnum.L_12,
            WordLanguageEnum.english
        );
        let mnemonic = ms.mnemonic;
        let seedBuffer = await ms.seed;
        let seed = seedBuffer.toString("hex");

        //生成引导地址(公链引导地址)
        let derve_path_external = BaseWallet.CreateDervePath(
            60,
            0,
            ChangeEnum.ExternalChain,
            0
        );

        //创建以太坊钱包
        let hdWallet = BaseWallet.CreateNewHDWallet(seedBuffer);

        //获取地址于私钥
        let address_and_key = BaseWallet.GetWalletAddressAndPrivateKey(
            hdWallet._hdwallet,
            derve_path_external
        );

        //公共扩展密钥
        let publicExtendedKey = address_and_key.publicExtendedKey;

        let address = address_and_key.address;
        let address_external = address_and_key.address;
        let privateKey = address_and_key.privateKey; //私钥
        let publicKey = address_and_key.publicKey.toString("hex"); //公钥
        return {
            mnemonic: mnemonic,
            address: address,
            privateKey: privateKey,
            publicKey: publicKey,
        };
    }

    /**
     * 转换成MD5
     * @param v 
     * @returns 
     */
    static ToMd5(v: string): string {
        const md5 = new SparkMD5();
        md5.appendBinary(v);
        return md5.end();
    }
    /**
     * 设置对象
     * @param to_obj 
     * @param from_obj 
     */
    static SetObj(to_obj: any, from_obj: any) {
        if (to_obj == null)
            to_obj = {};
        if (to_obj && from_obj) {
            for (const key in from_obj) {
                if (Object.prototype.hasOwnProperty.call(from_obj, key)) {
                    const v = from_obj[key];
                    to_obj[key] = v;
                }
            }
        }
    }

    static IsJson(arg0: string): boolean {
        try {
            if (JSON.parse(arg0))
                return true;
        }
        catch (e) {

        }

        return false;
    }

    static ToJsonVO(str: string): {
        flag: boolean,
        data: any
    } {
        try {
            return {
                flag: true,
                data: JSON.parse(str)
            };
        }
        catch {
            return {
                flag: false,
                data: null
            }
        }
    }
    /**
     * 倒序排列
     * @param arr 
     * @param select_pro 
     */
    public static OrderDesc<T>(arr: Array<T>, select_pro: (x: T) => number) {
        arr.sort((a, b) => {
            return select_pro(b) - select_pro(a);
        });
    }

    /**
     * 生成GUID
     */
    public static get GUID(): string {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
    }

    /**
     * 获取当前时间戳
     */
    public static get GetNowTime(): number {
        return Date.now();
    }

    /**
     * 时间转换成时间戳
     * @param v 
     * @returns 
     */
    public static TimeToLong(v: string | number): number {
        if (typeof v == "number")
            return v;
        let time = new Date(v);
        return time.getTime();
    }
    /**
     * 时间差转换成可读时间
     * @param difftime 时间差
     * @returns 
     */
    public static DifftimeToString(difftime: number, formake: "all" | "minutes" | "minutes_2" = "all"): string {
        try {

            difftime = difftime / 1000;
            let days = parseInt((difftime / 86400).toString());
            if (difftime < 86400)
                days = 0;
            let hours = parseInt((difftime / 3600).toString()) - 24 * days;
            if (difftime < 3600)
                hours = 0;
            let minutes = parseInt((difftime % 3600 / 60).toString());
            if (minutes <= 0)
                minutes = 0;
            let seconds = parseInt((difftime % 60).toString());
            if (seconds <= 0)
                seconds = 0;
            if (formake == "all") {
                if (days > 0) {
                    return `${days}天${hours}小时${minutes}分${seconds}秒`;
                }
                else if (days > 0 && hours > 0) {
                    return `${hours}时${minutes}分${seconds}秒`;
                }
                else {
                    return `${minutes}分${seconds}秒`;
                }
            }
            else if (formake == "minutes_2") {
                if (minutes > 0) {
                    return `${minutes}分${this.add0(seconds)}`;
                }
                else {
                    return `${this.add0(seconds)}`;
                }
            }
            else {
                return `${this.add0(minutes)}:${this.add0(seconds)}`;
            }

        }
        catch {

        }
        return "";
    }

    private static add0(m: number | string) {
        return (typeof m == "string" ? parseInt(m) : m) < 10 ? '0' + m : m
    }

    public static FormatAddress(address: string) {
        if (!address)
            return "";
        let ret_address = address;
        if (address.length > 10) {
            ret_address = address.substring(0, 6) + "..." + address.substring(address.length - 6);
        }
        return ret_address;
    }

    /**
     * 格式化时间到日期
     * @param _time 
     * @returns 
     */
    public static FormatDateTimeToDate(_time: number | string): string {
        if (_time == "" || _time == null)
            return _time;
        try {
            if (typeof _time == "string") {
                if (_time.indexOf("-") != -1 || _time.indexOf(":") != -1 || _time.indexOf('/') != -1) {

                }
                else {
                    _time = parseInt(_time);
                }
            }

            //shijianchuo是整数，否则要parseInt转换
            var time = new Date(_time);
            var y = time.getFullYear();
            var m = time.getMonth() + 1;
            var d = time.getDate();
            var h = time.getHours();
            var mm = time.getMinutes();
            var s = time.getSeconds();
            let show_time = `${y}-${this.add0(m)}-${this.add0(d)}`;
            return show_time;
        }
        catch (e) {
            return typeof _time == "number" ? _time.toString() : _time;
        }
    }

    /**
     * 格式化日期到时间
     * @param _time 
     * @returns 
     */
    public static FormatDateTimeToTime(_time: number | string): string {
        if (_time == "" || _time == null)
            return _time;
        try {
            if (typeof _time == "string") {
                if (_time.indexOf("-") != -1 || _time.indexOf(":") != -1 || _time.indexOf('/') != -1) {

                }
                else {
                    _time = parseInt(_time);
                }
            }

            //shijianchuo是整数，否则要parseInt转换
            var time = new Date(_time);
            var y = time.getFullYear();
            var m = time.getMonth() + 1;
            var d = time.getDate();
            var h = time.getHours();
            var mm = time.getMinutes();
            var s = time.getSeconds();
            let show_time = `${this.add0(h)}:${this.add0(mm)}:${this.add0(s)}`;
            return show_time;
        }
        catch (e) {
            return typeof _time == "number" ? _time.toString() : _time;
        }
    }

    /**
     * 格式化日期到时间 并没有秒数
     * @param _time 
     * @returns 
     */
    public static FormatDateTimeToTimeNoSecond(_time: number | string): string {
        if (_time == "" || _time == null)
            return _time;
        try {
            if (typeof _time == "string") {
                if (_time.indexOf("-") != -1 || _time.indexOf(":") != -1 || _time.indexOf('/') != -1) {

                }
                else {
                    _time = parseInt(_time);
                }
            }

            //shijianchuo是整数，否则要parseInt转换
            var time = new Date(_time);
            var y = time.getFullYear();
            var m = time.getMonth() + 1;
            var d = time.getDate();
            var h = time.getHours();
            var mm = time.getMinutes();
            var s = time.getSeconds();
            let show_time = `${this.add0(h)}:${this.add0(mm)}`;
            return show_time;
        }
        catch (e) {
            return typeof _time == "number" ? _time.toString() : _time;
        }
    }

    /**
     * 显示日期
     * */
    public static ShowDate(_time: number | string): string {

        if (_time == "" || _time == null)
            return _time;
        try {
            if (typeof _time == "string") {
                if (_time.indexOf("-") != -1 || _time.indexOf(":") != -1 || _time.indexOf('/') != -1) {

                }
                else {
                    _time = parseInt(_time);
                }
            }

            //shijianchuo是整数，否则要parseInt转换
            var time = new Date(_time);
            var y = time.getFullYear();
            var m = time.getMonth() + 1;
            var d = time.getDate();
            var h = time.getHours();
            var mm = time.getMinutes();
            var s = time.getSeconds();
            let show_time = `${this.add0(h)}:${this.add0(mm)}:${this.add0(s)}`;
            //检查是否是今天
            let now_time = new Date(this.GetNowTime);
            show_time = `${y}-${this.add0(m)}-${this.add0(d)}`;
            return show_time;
        }
        catch (e) {
            return typeof _time == "number" ? _time.toString() : _time;
        }
    }

    /**
     * Home页面时间显示方式
     * @param _time 
     * @returns 
     */
    public static ShowHomeDateTime(_time: number | string): string {
        if (typeof _time == "string") {
            if (_time.indexOf("-") != -1 || _time.indexOf(":") != -1 || _time.indexOf('/') != -1) {

            }
            else {
                _time = parseInt(_time);
            }
        }

        //shijianchuo是整数，否则要parseInt转换
        var time = new Date(_time);
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        let show_time = `${this.add0(h)}:${this.add0(mm)}:${this.add0(s)}`;
        //检查是否是今天
        let now_time = new Date(this.GetNowTime);
        if (now_time.getFullYear() == y && now_time.getMonth() + 1 == m && now_time.getDate() == d) {
            show_time = `${this.add0(h)}:${this.add0(mm)}`;
        }
        else {
            show_time = `${m}月${d}日`;
        }
        // else {
        //     show_time = `${y}-${this.add0(m)}-${this.add0(d)} ${this.add0(h)}:${this.add0(mm)}`;
        // }
        return show_time;
    }

    /**
     * 显示时间
     * @param time 时间
     * @returns 
     */
    public static ShowDateTime(_time: number | string): string {

        if (typeof _time == "string") {
            if (_time.indexOf("-") != -1 || _time.indexOf(":") != -1 || _time.indexOf('/') != -1) {

            }
            else {
                _time = parseInt(_time);
            }
        }

        //shijianchuo是整数，否则要parseInt转换
        var time = new Date(_time);
        var y = time.getFullYear();
        var m = time.getMonth() + 1;
        var d = time.getDate();
        var h = time.getHours();
        var mm = time.getMinutes();
        var s = time.getSeconds();
        let show_time = `${this.add0(h)}:${this.add0(mm)}:${this.add0(s)}`;
        //检查是否是今天
        let now_time = new Date(this.GetNowTime);
        if (now_time.getFullYear() == y && now_time.getMonth() + 1 == m && now_time.getDate() == d) {
            show_time = `${this.add0(h)}:${this.add0(mm)}`;
        }
        else {
            show_time = `${this.add0(m)}-${this.add0(d)} ${this.add0(h)}:${this.add0(mm)}`;
        }
        // else {
        //     show_time = `${y}-${this.add0(m)}-${this.add0(d)} ${this.add0(h)}:${this.add0(mm)}`;
        // }
        return show_time;
    }

    /**
     * 检测是否为空
     * @param v 
     * @returns 
     */
    public static IsNull(v?: string): boolean {
        if (v == null || v == "null" || v == undefined || v == "undefined" || v == "")
            return true;
        return false;
    }
    /**
     * 是否不是空
     * @param v 
     * @returns 
     */
    public static IsNotNull(v?: string): boolean {
        return !this.IsNull(v);
    }
    /**
     * 设置本地存储
     * @param key 
     * @param v 
     */
    public static SetLocalStorage(key: string, v: string): void {
        localStorage.setItem(key, v);
    }

    /**
     * 获取本地存储
     * @param key 
     * @returns 
     */
    public static GetLocalStorage(key: string, _default: string): string {
        let v = localStorage.getItem(key);
        if (Tools.IsNull(key))
            return "";
        return v as string;
    }


    /**
     * 弹出成功消息
     * @param SUCCESS_MESSAGE 
     */
    static AlertSuccess(SUCCESS_MESSAGE: string): void {
        console.log(`${SUCCESS_MESSAGE}`);

        Global.app && Global.app.ShowAlert(SUCCESS_MESSAGE, 1);
    }

    /**
     * 弹出失败消息
     * @param ERROR_MESSAGE 
     */
    static AlertError(ERROR_MESSAGE: string | undefined) {
        console.log(`${ERROR_MESSAGE}`);
        Global.app && Global.app.ShowAlert(ERROR_MESSAGE, 2);
    }
    /**
     * 跳转
     * @param href 字符串跳转 或 数字跳转 -1代表返回
     * @param query Get传参
     * @param params Post传参
     * @param to_replice 是否替换方式跳转
     */
    public static GoTo(href: string | number, query: any = {}, params: any = {}, to_replice: boolean = false): void {
        if (typeof href == "string") {
            let name: string = "";
            for (let i = 0; i < router.getRoutes().length; i++) {
                const v = router.getRoutes()[i];
                if (v.path == href) {
                    name = v.name?.toString() as string;
                }
            }

            Global.params_data_dic[href] = params;
            if (to_replice) {
                Global.app.$router.replace({
                    path: href,
                    query: query,
                    name: name,
                    params: params
                });
            }
            else {
                Global.app.$router.push({
                    path: href,
                    query: query,
                    name: name,
                    params: params
                });
            }
        }
        else if (typeof href == "number") {
            switch (href) {
                case -1:
                    Global.app.$router.back();
                    break;
                default:
                    Global.app.$router.go(href);
                    break;
            }
        }
    }

    /**
     * 获取当前页面URL路径 
     * @returns 
     */
    public static NowPageURL() {
        let v = router;
        let path = v.currentRoute.value.path;
        return path;
    }

    /**
     * 获取当前的Post传参
     * @returns 
     */
    public static GetParams(href?: any): any {
        if (Tools.IsNull(href)) {
            let v = router;
            let path = v.currentRoute.value.path;

            return Global.params_data_dic[path] ?? {};
        }
        else {
            //path
            if (href) {
                if (typeof href == "string") {
                    return Global.params_data_dic[href] ?? {};
                }
                else if (typeof href == "object") {
                    if (Tools.IsNotNull(href.path)) {
                        return Global.params_data_dic[href.path] ?? {};
                    }
                }
            }

            return {};
        }
    }

    /**
     * 当前路由页面
     * @returns 
     */
    public static GetNowRouteHref() {
        return Global.app && Global.app.$route.path;
    }

    /**
     * 获取当前的Get传参
     * @returns 
     */
    public static GetQuery(): any {
        return Global.app.$route.query;
    }

    /**
     * 显示LOG
     * @param tag 标签
     * @param msg 日志内容
     * @param color 颜色
     * @param font_size 字体大小
    */
    static ShowLog(tag: string, msg: any, color: string = "#0a0", font_size: number = 12): void {
        console.log(`%c【${tag}】:${msg}`, `color:${color};font-size:${font_size}px`);
    }


    /**
     * 异步上传文件
     * @param action 
     * @param data 
     * @param files 
     * @param success 
     * @returns 
     */
    static async UploadFileAsync(action: string, data: any, files: File[] | Blob): Promise<{
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: {
            OriginalFileNameNoExtension: string,
            OriginalFileName: string,
            FileName: string,
            FileURL: string,
            FileSize: number,
            MimeType: string,
            FileHash: string
        }[]
    }> {
        // Global.ShowLoading();
        return new Promise((successfun, errorfun) => {
            try {
                this.UploadFile(action, data, files, (res: {
                    state: number,
                    msgstate: string,
                    key: string,
                    error_msg: string,
                    data: any
                }) => {
                    try {
                        Tools.ShowLog(`HTML返回值【${action}】`, `${typeof res.data == "object" ? JSON.stringify(res.data) : res.data}`, "#1C86EE");
                        successfun(res);
                    }
                    finally {
                        // Global.HideLoading();
                    }
                });
            }
            finally {

            }
        });
    }
    /**
     * 上传文件
     * @param action 
     * @param data 
     * @param files 
     * @param success 
     */
    static UploadFile(action: string, data: any, files: File[] | Blob, success: (res: {
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: {
            OriginalFileNameNoExtension: string,
            OriginalFileName: string,
            FileName: string,
            FileURL: string,
            FileSize: number,
            MimeType: string,
            FileHash: string
        }[]
    }) => void) {
        if (data == null)
            data = {};
        Tools.ShowLog(action, "上传文件", "	#1C86EE");
        // data.login_token = Global.GetLoginToken;
        let url = `${Config.API_URL}?a=${action}` //API_URL + "?a=" + action;
        var xhr = new XMLHttpRequest();
        let formData = new FormData();

        for (let key in data) {
            url += `&${key}=${encodeURIComponent(data[key])}`;
        }

        if (files instanceof Blob) {
            formData.append('fileUpload', files, "new_file");
        }
        else {
            for (let i = 0; i < files.length; i++) {
                const f = files[i];
                formData.append('fileUpload', f);
            }
        }


        xhr.open("POST", url, true);
        // xhr.withCredentials = true;

        // xhr.setRequestHeader("Content-type", `multipart/form-data;`);
        // xhr.upload.onprogress = (ev) => {
        //     console.log(`进度:${Math.ceil(ev.loaded * 100 / ev.total)}%`);
        // };
        xhr.onload = function (e) {
            if (this.status == 200 || this.status == 304) {
                if (success != null)
                    success(JSON.parse(this.responseText));
            }
            else {
                Tools.AlertError("网络请求失败 错误:" + this.status);
            }
        };
        xhr.send(formData);
    }

    /**
     * 补0
     * @param num 
     * @param n 
     * @returns 
     */
    public static Pad(num: number, n: number): string {
        console.log(`要处理的数据:${num}`);
        let len = num.toString().length;
        let ret_num = num.toString();
        while (len < n) {
            ret_num = "0" + num;
            len++;
        }
        return ret_num;
    }

    /**
     * 获取远程URL数据
     * @param url 
     */
    static GetURLAsync(url: string, data: any, method: "POST" | "GET" = "POST") {
        return new Promise<string>((r, errfun) => {
            try {
                var xhr = new XMLHttpRequest();
                var formdata: string = "";
                for (let key in data) {
                    formdata += key + "=" + encodeURIComponent(data[key]) + "&";
                }
                if (formdata != "") {
                    formdata = formdata.substr(0, formdata.length - 1);
                }
                xhr.open("GET", url, true);
                xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

                xhr.onload = function (e) {
                    if (this.status == 200 || this.status == 304) {
                        r(this.responseText);
                    }
                    else {

                        errfun("网络请求失败 错误:" + this.status);
                    }
                };
                xhr.ontimeout = function (e) {
                    console.log(`请求超时........`);
                };
                xhr.onerror = function (e) {

                    errfun("请求失败!");
                };
                xhr.send(formdata);
            }
            catch (e) {

                errfun("出错了!");
            }
        });
    }

    /**
     * 获取HTML
     * @param action 
     * @param data 
     * @param success 
     * @param fail 
     */
    static GetHtml(action: string, data: any, success: (res: {
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: any
    }) => void, fail?: (err: any) => void, files: File[] = []) {
        try {
            if (data == null)
                data = {};

            data.use_platform_guid = Global.gzh_config_vo?.platform_guid ?? "";

            Tools.ShowLog(action, "HTML请求", "	#1C86EE");
            // data.login_token = Global.GetLoginToken;
            let url = `${Config.API_URL}?a=${action}`;

            var xhr = new XMLHttpRequest();
            var formdata: FormData | string;
            if (files.length > 0) {
                formdata = new FormData();
                for (let key in data) {
                    url += "&" + key + "=" + encodeURIComponent(data[key]);
                }
                // if (url != "") {
                //     url = url.substring(0, url.length - 1);
                // }
                if (files) {
                    if (files.length > 0) {
                        for (let i = 0; i < files.length; i++) {
                            const f = files[i];
                            formdata.append('fileUpload', f);
                        }
                    }
                }
            }
            else {
                formdata = "";
                for (let key in data) {
                    formdata += key + "=" + encodeURIComponent(data[key]) + "&";
                }
                if (formdata != "") {
                    formdata = formdata.substr(0, formdata.length - 1);
                }
            }
            xhr.open("POST", url, true);
            if (files.length > 0) {
                // xhr.setRequestHeader("Content-type", "multipart/form-data");
            }
            else {
                xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
            }


            xhr.onload = function (e) {
                try {
                    if (this.status == 200 || this.status == 304) {
                        if (success != null)
                            success(JSON.parse(this.responseText));
                    }
                    else {
                        Tools.AlertError("网络请求失败 错误:" + this.status);
                    }
                }
                catch (ex) {
                    console.error(ex);
                }
            };
            xhr.send(formdata);
        }
        catch (err) {
            console.error(err);
            console.log("加载HTML错误:" + err);
        }
    }

    /**
     * 异步请求
     * @param action 
     * @param data 
     * @param show_jiazaizhong 
     */
    static async GetHtmlAsync(action: string, data: any, files: File[] = []): Promise<{
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: any
    }> {
        return new Promise((successfun, errorfun) => {
            this.GetHtml(action, data, (res) => {
                Tools.ShowLog(`HTML返回值【${action}】`, `${typeof res.data == "object" ? JSON.stringify(res.data) : res.data}`, "#1C86EE");
                successfun(res);
            }, err => {
                Tools.ShowLog(`HTML请求出错【${action}】`, "HTML请求出错", "#8B0000");
                errorfun(err);
            }, files);
        });
    }

    static GetBlob(canvas: any) {
        //获取blob对象
        let base64 = canvas.toDataURL("image/jpeg", 1);
        let data = base64.split(",")[1];
        data = window.atob(data);
        let ia = new Uint8Array(data.length);
        for (var i = 0; i < data.length; i++) {
            ia[i] = data.charCodeAt(i);
        }
        return {
            blob: new Blob([ia], {
                type: "image/jpeg",
            }),
            base64: base64,
        };
    }

    /**
     * EDS加密
     * @param message 
     * @param password 
     * @returns 
     */
    static EncryptDes(message: string, password: string, mode: "CBC" | "ECB" | "OFB" | "CFB" | "CTS" = "CBC"): string {
        const keyHex = cryptoJs.enc.Utf8.parse(password)
        const option = { mode: cryptoJs.mode[mode], padding: cryptoJs.pad.Pkcs7, iv: keyHex }
        const encrypted = cryptoJs.DES.encrypt(message, keyHex, option);
        return encrypted.ciphertext.toString() // 返回hex格式密文，如需返回base64格式：encrypted.toString()
    }

    /**
     * EDS解密
     * @param message 要解密的内容
     * @param password 
     * @returns 
     */
    static DecryptDes(message: string, password: string, mode: "CBC" | "ECB" | "OFB" | "CFB" | "CTS" = "CBC"): string {
        const keyHex = cryptoJs.enc.Utf8.parse(password)
        const decrypted = cryptoJs.DES.decrypt(
            {
                ciphertext: cryptoJs.enc.Hex.parse(message)
            },// 若message是base64格式，则无需转16进制hex，直接传入message即可
            keyHex,
            {
                mode: cryptoJs.mode[mode],
                padding: cryptoJs.pad.Pkcs7,
                iv: keyHex
            }
        )
        return decrypted.toString(cryptoJs.enc.Utf8)
    }

    /**
     * 检测如果是空
     * @param msg 
     */
    static StrIsNull(msg?: string | null): boolean {
        if (msg == "" || msg == null || msg == "undefined" || msg == "null") {
            return true;
        }
        return false;
    }

    /**
     * 检测字符串不是空
     * @param msg 
     */
    static StrIsNotNull(msg?: string | null): boolean {
        return !this.StrIsNull(msg);
    }


    static RemoveLocalStorage(key: string): void {
        localStorage.removeItem(key);
    }


    /**
     * 设置SESSION
     * @param key 
     * @param value 
     */
    static SetSessionStorage(key: string, value: string): void {
        sessionStorage.setItem(key, value);
    }

    /**
     * 获取SESSION值
     * @param key 
     */
    static GetSessionStorage(key: string): string | null {
        return sessionStorage.getItem(key);
    }

    static RemoveSessionStorage(key: string) {
        sessionStorage.removeItem(key);
    }

    /**
     * 清空全部session
     */
    static RemoveAllSession() {
        sessionStorage.clear();
    }
    /**
     * 检测数据包是否正确
     * @param v 
     * @returns 
     */
    public static CheckDataIsOK(v: {
        flag: boolean,
        _this: any,
        err_msg: string,
        data_vo: BackDataVO | null
    } | null, show_error_msg: boolean = true): boolean {
        if (v != null) {
            if (v.flag) {
                if (v.data_vo != null) {
                    switch (v.data_vo.STATUS) {
                        case -99: //未登录
                            Tools.GoTo("/");
                            return false;
                        case 0: //请求失败
                            if (show_error_msg)
                                Tools.AlertError(v.data_vo.ERROR_MESSAGE);
                            break;
                        case 1: //请求正常返回
                            return true;
                    }
                }

            }
        }
        return false;
    }
    /**
     * 检测数据是否正确
     * @param res 
     * @param show 是否显示 默认显示错误提示
     */
    static CheckHtmlIsOK(res: {
        state: number,
        msgstate: string,
        key: string,
        error_msg: string,
        data: any
    }, show_error_msg: boolean = true) {
        switch (res.state) {
            case -99:
                Tools.GoTo("/");
                if (show_error_msg) {

                    Tools.AlertError("您还没有登录!");
                }
                return false;
            case 1:
                return true;
            default:
                if (show_error_msg) {
                    Tools.AlertError(res.error_msg);
                }
                else {
                    console.log(`错误:${res.error_msg}`);
                }
                return false;
        }
    }

    /**
     * 时间戳转换成时分秒
     * @param timestamp 
     */
    static UnixTimestampToDateTime(timestamp: number): string {
        if (timestamp.toString().length == 10)
            timestamp *= 1000;
        var date = new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = date.getDate() + ' ';
        var h = date.getHours() + ':';
        var m = date.getMinutes() + ':';
        var s = date.getSeconds();
        return Y + M + this.add0(D) + this.add0(h) + this.add0(m) + this.add0(s.toString());
    }
}