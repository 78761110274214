import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    meta: {
      title: "微信登录"
    },
    component: () => import("@/views/Index.vue")
  },
  {
    path: '/reg',
    name: 'reg',
    meta: {
      title: "账号注册"
    },
    component: () => import("@/views/Reg.vue")
  },
  {
    path: '/down',
    name: "Down",
    meta: {
      title: "下载"
    },
    component: () => import("@/views/Down.vue")
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
