import { createApp,reactive } from 'vue'
import App from './App.vue'
import router from './router'
import Global from './ts/Global';
import Tools from './ts/Tools';
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title as string;
    }
    next();
});

/**
 *
 */
async function GetShareConfigDataFromHOSTAsync() {

    let res = await Tools.GetHtmlAsync("GetShareConfigDataFromHOST", {
        host: window.location.origin,
    });
    if (Tools.CheckHtmlIsOK(res)) {
        let d = res.data;

        Global.gzh_config_vo =reactive(d.gzh_config_vo);
        Global.platform_vo = reactive(d.platform_vo);
        createApp(App).use(router).mount('#app');
    }
}

GetShareConfigDataFromHOSTAsync();