import Config from "./Config";
import { InputBoxTypeEnum, SlideVerificationTypeEnum } from "./SuperEnum";
import Tools from "./Tools";
import PlatformVO from "./vo/PlatformVO";
import SysSubstationConfiguration from "./vo/SysSubstationConfiguration";
export default class Global {
    public static is_start = false;
    public static app: any = null;
    public static home: any = null;
    public static head: any = null;
    public static menu_vo: any = null;
    public static open_chat_window: any = null;
    public static gzh_config_vo: SysSubstationConfiguration;
    public static platform_vo: PlatformVO;

    /**
     * 是否为长按
     */
    public static LongTouch: boolean = false;


    public static test_home_data: any = null;

    /**
     * 音视频通讯TOKEN
     */
    public static IM_TOKEN: string = '';

    /**
     * 最后的参数
     */
    public static params_data_dic: { [url: string]: any } = {};

    /**
     * 音频播放进度
     */
    public static play_voice_run_dic: {
        [url: string]: {
            time: number
        }
    } = {};

    /**
     * 上传文件进度队列
     */
    public static upload_file_progress_dic: {
        [guid: string]: {
            time: number,
            progress: number,
            progress_call_fun: (v: number) => void,
            finish_call_fun: (flag: boolean) => void
        }
    } = {};

    /**
     * 显示助记词WINDOW窗口
     * @param login_vue 
     */
    public static ShowMnemonicWindow(login_vue: any = null) {
        Global.app && Global.app.ShowMnemonicWindow(login_vue);
    }

    /**
     * 关闭助记词WINDOW窗口
     */
    public static CloseMnemonicWindow() {
        Global.app && Global.app.CloseMnemonicWindow();
    }



    /**
     * 显示弹出框
     * @param message 
     */
    public static AlertSuccess(message: string): void {
        Global.app?.ShowAlert(message, 1);
    }

    public static AlertError(message: string): void {
        console.error(message);

        Global.app?.ShowAlert(message, 2);
    }

    /**
     * 获取图标
     * @param url 
     */
     public static GetCoinIconURL(url: string, pic_mode: "none" | "800" | "300" | "160" | "f" | "f160" = "none") {
        let pic_mode_url = "";
        if (Tools.IsNotNull(url)) {
            if (typeof url == "string") {
                if (!url.startsWith("data:"))
                    url = this.guolv_url(url);
                if (url.startsWith("file:"))
                    return url;
            }
        }
        switch (pic_mode) {
            case "800":
                pic_mode_url = "_800";
                break;
            case "300":
                pic_mode_url = "_300";
                break;
            case "160":
                pic_mode_url = "_160";
                break;
            case "f":
                pic_mode_url = "_f";
                break;
            case "f160":
                pic_mode_url = "_f_160";
                break;
        }

        if (Tools.IsNotNull(url)) {
            if (url.startsWith("data:"))
                return url;
            if (url.startsWith("image/") || url.startsWith("/image/"))
                return url;
            if (url.startsWith("http://") || url.startsWith("https://")) {
                if (url.endsWith("_lookbao")) {
                    //检测是否存在原资源地址没有改成CDN的
                    url = url.replace(Config.RES_HOST, Config.CDN_RES_HOST);
                    return `${url}${pic_mode_url}`;
                }
                else {
                    return url;
                }
            }
            return this.guolv_url(`${Config.CDN_RES_URL}/${url}${url.endsWith("_lookbao") ? pic_mode_url : ''}`);
        }
        else {
            return "";
        }
    }

    private static guolv_url(url: string): string {
        if (Tools.StrIsNotNull(url)) {
            if (typeof url == "string") {
                if (url.replaceAll != null && url.replace != null) {
                    url = url.replaceAll('//', '/');
                    url = url.replace("http:/", "http://");
                    url = url.replace("https:/", "https://");
                }
            }
        }
        return url;
    }

    /**
     * 打开验证码
     * @param slide_verification_type 
     * @param slide_call_back 
     * @param slide_phone_num 手机号
     * @param extend_obj 扩展数据
     */
    public static OpenSlideVerification(
        extend_obj: any,
        slide_verification_type: SlideVerificationTypeEnum,
        slide_success_callback: (d: any) => void,
        slide_error_callback: (msg: string) => void,
        slide_cancel_callback: () => void,
    ) {
        switch (slide_verification_type) {
            case SlideVerificationTypeEnum.VerificationPhone: //手机号申请验证
                if (Tools.IsNull(extend_obj?.phone)) {
                    Tools.AlertError("手机号不能为空!");
                    slide_cancel_callback && slide_cancel_callback();
                    return;
                }
                break;
            case SlideVerificationTypeEnum.AccountAuthorization: //账号授权
                if(Tools.IsNull(extend_obj?.pwd))
                {
                    Tools.AlertError("账号密码不能为空!");
                    slide_cancel_callback && slide_cancel_callback();
                    return;
                }
                break;
            default:
                Tools.AlertError("验证类型错误!");
                return;
        }
        Global.app && Global.app.OpenSlideVerification(extend_obj,slide_verification_type, slide_success_callback, slide_error_callback, slide_cancel_callback);
    }

    /**
     * 关闭验证码
     */
    public static CloseSlideVerification() {
    Global.app && Global.app.CloseSlideVerification();
}

    /**
     * 弹出消息框
     * @param title 
     * @param content 
     * @param success_fun 
     * @param cancel_fun 
     * @param open_no_ti_shi 是否显示不在提示控件
     * @param other_tishi_data 参与运算提示框特殊内容(只影响是否显示提示框参加MD5加密)
     */
    public static MessageBox(title: string, content: string, success_fun: () => void, cancel_fun: (() => void) | null = null, open_no_ti_shi: boolean = false, other_tishi_data: string = "") {
    Global.app?.MessageBox(title, content, success_fun, cancel_fun, open_no_ti_shi, other_tishi_data);
}


    /**
     * 显示输入框
     */
    public static ShowInputBox(title: string, content: string, input_box_type: InputBoxTypeEnum = InputBoxTypeEnum.TextArea, call_fun: ((txt: string) => void) | null = null) {
    Global.app.ShowInputBox(title, content, input_box_type, call_fun);
}


    /**
     * 显示输入框
     * @param title 
     * @param content 
     * @returns 
     */
    public static ShowInputBoxAsync(title: string, content: string, input_box_type: InputBoxTypeEnum = InputBoxTypeEnum.TextArea, max_length: number = 0): Promise < string > {
    return new Promise<string>((r) => {
        Global.app.ShowInputBox(title, content, input_box_type, (txt: string) => {
            r(txt);
        }, max_length);
    });
}





    /**
     * 隐藏Loading窗口
     */
    public static HideGlobalLoading() {
    Global.app && Global.app.HideGlobalLoading();
}

    /**
     * 显示全局Loading
     * @param msg 
     */
    public static ShowGlobalLoading<T>(msg: string, call_fun: (m: {
    SetLoadingMsg: (msg: string) => void
}) => T): T {
    Global.app && Global.app.ShowGlobalLoading(msg);
    let _m = {
        SetLoadingMsg: (v: string) => {
            Global.app && Global.app.ShowGlobalLoading(v);
        }
    };
    let v = null;
    try {
        v = call_fun(_m);
    }
    catch (e) {

    }
    finally {
        Global.app && Global.app.HideGlobalLoading();
    }
    return v as T;
}

    /**
     * 显示全局Loading
     * @param msg 
     * @param call_fun_async 
     * @param no_close_loading 完成后不关闭loading窗口
     */
    public static async ShowGlobalLoadingAsync<T>(msg: string, call_fun_async: (m: {
    SetLoadingMsg: (msg: string) => void
}) => Promise<T>, no_close_loading: boolean = false): Promise < T > {
    Global.app && Global.app.ShowGlobalLoading(msg);
    let _m = {
        SetLoadingMsg: (v: string) => {
            Global.app.ShowGlobalLoading(v);
        }
    };
    let ret = null;
    try {
        ret = await call_fun_async(_m);
    }
        catch(e: any) {
        Tools.AlertError(e);
    }

        if(!no_close_loading)
            Global.app && Global.app.HideGlobalLoading();
    return ret as T;
}

    /**
     * 更新我的登录数据
     */
    public static async UpdateMyLoginUserAsync() {
    Global.app && (await Global.app?.UpdateMyLoginUserAsync());
}


    /**
     * 获取系统钱包地址
     */
    public static async GetSystemWalletAddressAsync(): Promise < {
    flag: boolean,
    wallet_address: string
} > {
    let res = await Tools.GetHtmlAsync("GetSystemWalletAddress", {});
    if(Tools.CheckHtmlIsOK(res, false)) {
    let wallet_address = res.data.sys_address;
    return {
        flag: true,
        wallet_address: wallet_address
    };
}
return {
    flag: false,
    wallet_address: ""
};
    }

    /**
     * 格式化地址
     * @param address 
     */
    public static FormatAddress(address: string) {
    try {
        let start = address.substring(0, 6);
        let end = address.substring(address.length - 4, address.length);
        return `${start}...${end}`;
    }
    catch {
        return address;
    }
}
}