/**
 * 调试模式下 并不启用远程数据库节点配置表
 */
export const IS_DEBUG: boolean = false;
export const Local_URL: string = "192.168.43.10";
export const UseSSL: boolean = false;
export const IS_TEST: boolean = false;

/**
 * 配置表
 */
export default class Config {
    public static WEB_API_HOST: string = IS_DEBUG ? `http://${Local_URL}:1001` : "http://webapi.lookbao.net";
    public static RES_HOST: string = IS_DEBUG ? `http://${Local_URL}:11111` : `${UseSSL ? 'https' : 'http'}://${IS_TEST ? 'res.81002200.com:11111' : 'res.lookbao.net'}`; //原资源地址
    public static CDN_RES_HOST: string = IS_DEBUG ? `http://${Local_URL}:11111` : `http://${IS_TEST ? 'cdn.res.81002200.com:11111' : 'cdn.res.lookbao.net'}`;

    /**
     * 网站API接口地址
     */
    public static API_URL = `${Config.WEB_API_HOST}`;

    /**
     * 用户APPID
     */
    public static APP_ID = "wx4540914629b31eaf";
    /**
     * 跳转路径
     */
    public static RedirectURI = "http://webapi.lookbao.net/GZHLogin";
    /**
     * 资源URL地址
     */
    public static CDN_RES_URL = `${Config.CDN_RES_HOST}`;
}